<template>
    <div class="space-y-4">
        <ul class="space-y-1">
            <router-link v-if="permissions.includes('VIEW_COURSE')" :to="{ name: 'Courses' }"
                :class="{ 'text-bondi-blue-600 bg-white': $route.name === 'Courses' }"
                class="flex flex-row space-x-2 p-2 px-4 ml-4 rounded-l-xl items-center">
                <CalendarDaysIcon class="h-5 w-5" /><span>Courses</span>
            </router-link>

            <router-link v-if="permissions.includes('VIEW_USER')" :to="{ name: 'Configurations' }"
                :class="{ 'text-bondi-blue-600 bg-white': $route.name === 'Configurations' }"
                class="flex flex-row space-x-2 p-2 px-4 ml-4 rounded-l-xl items-center">
                <Cog8ToothIcon class="h-5 w-5" /><span>Configurations</span>
            </router-link>
        </ul>
    </div>
</template>

<script>
import { Cog8ToothIcon, CalendarDaysIcon } from '@heroicons/vue/24/solid'
import { useAuthStore } from "@/store/authStore";

export default {
    name: 'SidebarView',
    components: {
        Cog8ToothIcon, CalendarDaysIcon
    },
    setup() {
        const authStore = useAuthStore()
        const permissions = authStore.permissions
        return { permissions }
    },
}
</script>