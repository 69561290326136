<template>
    <div class="flex sm:flex-col flex-col flex-1 bg-wild-sand border-t-8 border-bondi-blue-500">
        <div class="sm:w-12/12 w-12/12 drop-shadow-md mb-4 ">
            <WebHeader></WebHeader>
        </div>
        <div class="flex flex-col flex-1 items-center justify-center">
            <router-view></router-view>
        </div>
        <div class="flex flex-col sm:w-12/12 w-12/12 sm:p-0 space-y-6">
            <FooterView></FooterView>
        </div>
    </div>
</template>
<script>
import WebHeader from '@/includes/WebHeader.vue'
import FooterView from '@/includes/WebFooter.vue'

export default {
    name: 'WebLayout',
    components: {
        WebHeader, FooterView
    },

    data() {
        return {
            currentYear: null,
        };
    },
    mounted() {
        const options = { year: 'numeric' };
        this.currentYear = new Date().toLocaleDateString(undefined, options);
    },
}
</script>
