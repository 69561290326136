<template>
    <div class="bg-mercury-500 text-abbey">
        <div
            class="flex sm:flex-row flex-col items-center justify-between text-sm sm:px-16 px-4 py-4 bg-daintree-800 text-white-100 font-roboto">
            <div class="flex sm:flex-row flex-col items-center space-x-4 text-sm">
                <p>&copy; {{ currentYear }} All Rights Reserved | RHG Language Hub</p>
                <p>Privacy Policy</p>
                <p>Cookie Preferences</p>
            </div>
            <!-- <div>Social Media</div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: "FooterView"
}
</script>
