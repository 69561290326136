import { createRouter, createWebHashHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import WebLayout from "@/layouts/WebLayout.vue";
import NotFoundView from "@/views/NotFound.vue";

const routeComponents = {
  MainLayout,
  WebLayout,
  HomeView: () => import("@/views/web/Home.vue"),
  LoginView: () => import("@/views/auth/Login.vue"),
  RegisterView: () => import("@/views/auth/Register.vue"),
  ResetPasswordView: () => import("@/views/auth/ResetPassword.vue"),
  // DashboardView: () => import("@/views/main/dashboard/Dashboard.vue"),
  WebServicesView: () => import("@/views/web/WebServices.vue"),
  WebAboutView: () => import("@/views/web/WebAbout.vue"),
  WebTestimonialsView: () => import("@/views/web/WebTestimonials.vue"),
  WebTutorsView: () => import("@/views/web/WebTutors.vue"),

  CoursesView: () => import("@/views/main/courses/Courses.vue"),
  AddCourseView: () => import("@/views/main/courses/Add.vue"),
  CourseView: () => import("@/views/main/courses/Course.vue"),
  EditCourseView: () => import("@/views/main/courses/Edit.vue"),
  AccessCourseView: () => import("@/views/main/courses/AccessCourse.vue"),

  ConfigurationsView: () =>
    import("@/views/main/configurations/Configurations.vue"),
  ContactsView: () => import("@/views/main/configurations/Contacts.vue"),

  UsersView: () => import("@/views/main/configurations/users/Users.vue"),
  AddUserView: () => import("@/views/main/configurations/users/Add.vue"),
  UserView: () => import("@/views/main/configurations/users/User.vue"),
  EditUserView: () => import("@/views/main/configurations/users/Edit.vue"),

  RolesView: () => import("@/views/main/configurations/roles/Roles.vue"),
  AddRoleView: () => import("@/views/main/configurations/roles/Add.vue"),
  RoleView: () => import("@/views/main/configurations/roles/Role.vue"),
  EditRoleView: () => import("@/views/main/configurations/roles/Edit.vue"),

  CourseTypesView: () =>
    import("@/views/main/configurations/course-types/CourseTypes.vue"),
  AddCourseTypeView: () =>
    import("@/views/main/configurations/course-types/Add.vue"),
  EditCourseTypeView: () =>
    import("@/views/main/configurations/course-types/Edit.vue"),

  MyProfileView: () =>
    import("@/views/main/configurations/users/MyProfile.vue"),
  EditProfileView: () =>
    import("@/views/main/configurations/users/EditProfile.vue"),
};

// Define your route configurations
const routes = [
  {
    path: "/",
    component: routeComponents.WebLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: routeComponents.HomeView,
        props: true,
      },
      {
        path: "/login",
        name: "Login",
        component: routeComponents.LoginView,
      },
      {
        path: "/register",
        name: "Register",
        component: routeComponents.RegisterView,
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: routeComponents.ResetPasswordView,
      },
      {
        path: "/web-services",
        name: "WebServices",
        component: routeComponents.WebServicesView,
      },
      {
        path: "/testimonials",
        name: "WebTestimonials",
        component: routeComponents.WebTestimonialsView,
      },
      {
        path: "/tutors",
        name: "WebTutors",
        component: routeComponents.WebTutorsView,
      },
      {
        path: "/about-us",
        name: "WebAbout",
        component: routeComponents.WebAboutView,
      },
    ],
  },
  {
    path: "/",
    name: "Main",
    component: routeComponents.MainLayout,
    children: [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   component: routeComponents.DashboardView,
      // },

      {
        path: "/courses",
        name: "Courses",
        component: routeComponents.CoursesView,
      },

      {
        path: "/add-course",
        name: "AddCourse",
        component: routeComponents.AddCourseView,
      },

      {
        path: "/course/:id",
        name: "Course",
        component: routeComponents.CourseView,
      },

      {
        path: "/edit-course/:id",
        name: "EditCourse",
        component: routeComponents.EditCourseView,
      },

      {
        path: "/access-course/:id",
        name: "AccessCourse",
        component: routeComponents.AccessCourseView,
      },

      {
        path: "/users",
        name: "Users",
        component: routeComponents.UsersView,
      },

      {
        path: "/add-user",
        name: "AddUser",
        component: routeComponents.AddUserView,
      },

      {
        path: "/user/:id",
        name: "User",
        component: routeComponents.UserView,
      },

      {
        path: "/my-profile/:id",
        name: "MyProfile",
        component: routeComponents.MyProfileView,
      },

      {
        path: "/edit-profile/:id",
        name: "EditProfile",
        component: routeComponents.EditProfileView,
      },

      {
        path: "/edit-user/:id",
        name: "EditUser",
        component: routeComponents.EditUserView,
      },

      {
        path: "/roles",
        name: "Roles",
        component: routeComponents.RolesView,
      },

      {
        path: "/add-role",
        name: "AddRole",
        component: routeComponents.AddRoleView,
      },

      {
        path: "/role/:id",
        name: "Role",
        component: routeComponents.RoleView,
      },

      {
        path: "/edit-role/:id",
        name: "EditRole",
        component: routeComponents.EditRoleView,
      },

      {
        path: "/configurations",
        name: "Configurations",
        component: routeComponents.ConfigurationsView,
      },

      {
        path: "/contacts",
        name: "Contacts",
        component: routeComponents.ContactsView,
      },

      {
        path: "/course-types",
        name: "CourseTypes",
        component: routeComponents.CourseTypesView,
      },

      {
        path: "/add-course-type",
        name: "AddCourseType",
        component: routeComponents.AddCourseTypeView,
      },

      {
        path: "/edit-course-type/:id",
        name: "EditCourseType",
        component: routeComponents.EditCourseTypeView,
      },
    ],
  },
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFoundView },
];

// Create the router instance
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
