<template>
    <div class="flex flex-row sm:px-16 px-4 py-2 items-center justify-between bg-white-300 border-solid relative z-50">
        <div class="flex sm:flex-row items-center space-x-2 flex-1">
            <router-link :to="{ name: 'Home' }">
                <img src="@/assets/images/logo.png" class="sm:h-24 h-12">
            </router-link>
        </div>

        <div class="hidden sm:flex flex-row items-center justify-between space-x-2 flex-1">
            <div class="">
                <router-link :to="{ name: 'Home' }" class="hover:text-bondi-blue-500"
                    :class="{ 'text-bondi-blue-600 bg-white border-b-2 p-2 border-bondi-blue-600': $route.name === 'Home' }"
                    @click="closeMenu">
                    Home
                </router-link>
            </div>

            <div class="">
                <router-link :to="{ name: 'WebAbout' }" class="hover:text-bondi-blue-500"
                    :class="{ 'text-bondi-blue-600 bg-white border-b-2 p-2 border-bondi-blue-600': $route.name === 'WebAbout' }"
                    @click="closeMenu">
                    About us
                </router-link>
            </div>

            <div class="">
                <router-link :to="{ name: 'WebServices' }" class="hover:text-bondi-blue-500"
                    :class="{ 'text-bondi-blue-600 bg-white border-b-2 p-2 border-bondi-blue-600': $route.name === 'WebServices' }"
                    @click="closeMenu">
                    Services
                </router-link>
            </div>

            <!-- <div class="">
                <router-link :to="{ name: 'WebTestimonials' }" class="hover:text-bondi-blue-500"
                    :class="{ 'text-bondi-blue-600 bg-white border-b-2 p-2 border-bondi-blue-600': $route.name === 'WebTestimonials' }"
                    @click="closeMenu">
                    Testimonials
                </router-link>
            </div> -->
            <div class="">
                <router-link :to="{ name: 'WebTutors' }" class="hover:text-bondi-blue-500"
                    :class="{ 'text-bondi-blue-600 bg-white border-b-2 p-2 border-bondi-blue-600': $route.name === 'WebTutors' }"
                    @click="closeMenu">
                    The Tutor
                </router-link>
            </div>
            <div class="flex space-x-2">
                <router-link :to="{ name: 'Login' }"
                    class="sm:px-8 px-2 sm:py-2 py-1 border border-bondi-blue-500 text-stone-100 hover:bg-bondi-blue-600"
                    @click="closeMenu">
                    Sign In
                </router-link>
            </div>
        </div>
        <div class="sm:hidden flex items-center">
            <Bars3CenterLeftIcon @click="toggleMenu" class="h-8 w-8 text-bondi-blue-500 focus:outline-none" />
        </div>
        <transition name="fade" class="">
            <div v-if="isMenuOpen"
                class="sm:hidden flex flex-col items-start space-y-2 bg-bondi-blue absolute top-full left-0 w-full px-4 py-2 ">
                <router-link :to="{ name: 'Home' }" class="block w-full py-2" @click="closeMenu">Home</router-link>
                <router-link :to="{ name: 'Login' }" class="block w-full py-2" @click="closeMenu">Sign In</router-link>
                <router-link :to="{ name: 'Register' }" class="block w-full py-2" @click="closeMenu">Sign
                    Up</router-link>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="isMenuOpen" class="sm:hidden fixed inset-0 bg-black bg-opacity-50" @click="toggleMenu">
            </div>
        </transition>

    </div>
</template>

<script>
import { Bars3CenterLeftIcon } from '@heroicons/vue/24/solid'
export default {
    name: "FooterView",
    components: { Bars3CenterLeftIcon },
    data() {
        return {
            isMenuOpen: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu() {
            this.isMenuOpen = false;
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
